<template>
  <v-dialog
    v-model="shower"
    persistent
    :max-width="widthDialog"
    style="overflow-x: hidden"
  >
    <v-card>
      <v-card-title class="teal darken-3" dark>
        <span
          class="font-weight-medium white--text text-center"
          style="font-size: 18px"
        >
        {{ $t("verifyOTP") }}
        </span>
      </v-card-title>

      <v-card-text class="mt-6 pt-3">
        <v-form ref="form" v-if="$t('default') === 'th'" class="text-center">
          <span class="text-subtitle-2 font-weight-black text-center" >
            &nbsp;&nbsp; {{ $t("company") }} {{ data2fa.business_name_th }} </span
          ><br />
          <span class="text-subtitle-2 font-weight-black text-center">
            &nbsp;&nbsp; {{ $t("text2fa") }} </span
          ><br />
          <span class="text-subtitle-2 font-weight-black">
            &nbsp;&nbsp; {{ $t("syssendotp") }} {{ data2fa.mobile_no_hide }}
          </span><br/>
          <span class="text-subtitle-2 font-weight-black" style="color: red;" v-if="manyreqotp">
            &nbsp;&nbsp; *** {{ $t("tomanyreqotp") }} ***
          </span>
        
        </v-form>
        <v-form ref="form" v-else class="text-center">
          <span class="text-subtitle-2 font-weight-black text-center">
            &nbsp;&nbsp;  {{ data2fa.business_name_eng }} {{ $t("company") }}</span
          ><br />
          <span class="text-subtitle-2 font-weight-black text-center">
            &nbsp;&nbsp; {{ $t("text2fa") }} </span
          ><br />
          <span class="text-subtitle-2 font-weight-black">
            &nbsp;&nbsp; {{ $t("syssendotp") }} {{ data2fa.mobile_no_hide }}
          </span><br />
          <span class="text-subtitle-2 font-weight-black" style="color: red;" v-if="manyreqotp">
            &nbsp;&nbsp;  *** {{ $t("tomanyreqotp") }} ***
          </span>
        </v-form>
       
        <v-flex
          dlex
          class="pb-5 justify-center align-center mt-5"
          v-if="reqotp === true"
        >
        <v-divider></v-divider>
        <br/>
          <div>
            <div class="mt-n2 pb-2">
              <!-- <span class="text-subtitle-2">
                {{ $t("otp_botonebox.enter_otp2") }} : &nbsp;&nbsp;
              </span>
              &nbsp;&nbsp; -->
              <span class="text-subtitle-2 font-weight-black">
                &nbsp;&nbsp; Ref < {{data_otp.refcode}} >
              </span>
            </div>

            <div>
              <otp-input
                @on-complete="onCompleteOTP"
                @on-changed="onChangedOTP"
                @on-paste="onPasteOTP"
                :separateInputClass="otpclass"
                :autoFocus="false"
                activeWrapperClass="mai-finished"
                placeholder=""
                :isDisabled="isOtptimeout || isloading"
                :isValid="false"
                :digits="6"
              >
              </otp-input>
            </div>

            <!-- <div
              v-if="this.checkIsvalidOTP === false"
              d-flex
              class="mt-2 text-center"
              style="background-color: rgb(230, 230, 230)"
            >
              <div class="align-center justify-center">
                <v-icon color="warning"> mdi-alert-circle </v-icon>
                <span class="text-subtitle-2 font-weight-bold ml-1">
                  {{ messageerrorotp }}
                </span>
              </div>
            </div> -->
          </div>
          <div class="align-self-end mt-3 pb-0 d-flex">
            <span :class="coveredTimeFormat_style">
              {{ coverTimeFormat }}
            </span>
            <v-spacer></v-spacer>
            <v-flex lg4 class="d-flex justify-end">
                <v-btn
                    text
                    color="primary"
                    style="text-decoration: underline;"
                    :disabled="!reOtptimeout"
                    @click="reOtptimeout = false, requiredotp2fa()"
                >
                    {{ $t("otp_botonebox.sendagain") }}
                </v-btn>
            </v-flex>
            <!-- <v-icon
              @click="requiredotp2fa"
              :disabled="isloading"
              v-if="!isOtptimeout"
            >
              mdi-refresh
            </v-icon> -->
          </div>
        </v-flex>
      </v-card-text>
      <v-card-actions v-if="reqotp === true">
        <v-spacer></v-spacer>
        <v-btn
          depressed
          color="error"
          class="text-button"
          :style="btnAction"
          :width="widthResendBtn"
          @click="closeDialog"
          :disabled="isloading || loginloading"
        >
          <span> {{ $t("otp_botonebox.cancel_otp_btn") }} </span>
        </v-btn>
        <v-btn
          depressed
          class="text-button"
          color="teal"
          :dark="isloading ? false : true"
          :style="btnAction"
          :width="widthResendBtn"
          @click="checkvalidotp"
          :disabled="isloading"
          :loading="loginloading"
        >
          <span> {{ $t("otp_botonebox.verify_otp") }} </span>
        </v-btn>
      </v-card-actions>
      <v-card-actions v-else>
        <v-spacer></v-spacer>
        <v-btn
          depressed
          color="error"
          class="text-button"
          :style="btnAction"
          :width="widthResendBtn"
          @click="closeDialog"
          :disabled="isloading || loginloading"
        >
          <span> {{ $t("otp_botonebox.cancel_otp_btn") }} </span>
        </v-btn>
        <v-btn
          depressed
          class="text-button"
          color="teal"
          :dark="isloading ? false : true"
          :style="btnAction"
          :width="widthResendBtn"
          @click="requiredotp2fa"
          :disabled="isloading"
          :loading="loginloading"
        >
          <span> {{ $t("otp_botonebox.send_otp_btn") }} </span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import OtpInput from "otp-input-vue2";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Swal from "sweetalert2/dist/sweetalert2.js";
import formatDatetime from "../../globalFunctions/formatDatetime";
import VueCookies from "vue-cookies";
import CryptoJS from "crypto-js";
import axios from "axios";
import { log } from "console";
import gbfGenerate from "@/globalFunctions/generateAuthorize";

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000
});

export default {
  mixins: [validationMixin],
  validations: {
    phonenumber: { required },
    reason_login: { required },
  },
  components: {
    OtpInput,
  },
  props: ["show", "data2fa","loginloading"],
  computed: {
    ...mapState(["username", "authorize", "account_active", "color"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
    ]),
    // Computed class or style
    otpclass() {
      if (this.checkIsvalidOTP === true) {
        console.log("True ");
        return "mai-classsed";
      } else {
        console.log("Falsde ");
        return "mai-classsed error-class";
      }
    },
    heightDialog() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    widthResendBtn() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "40%";
        default:
          return "35%";
      }
    },
    widthDialog() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 340; // 340
        default:
          return 450;
      }
    },
    btnAction() {
      return "font-size: 16px; font-weight: lighter;";
    },
    coveredTimeFormat_style() {
      if (this.isOtptimeout) {
        return "subtitle-2 red--text mt-2";
      } else {
        return `subtitle-2 mt-2`;
      }
    },

    // Computed logic
    coverTimeFormat() {
      if (this.isOtptimeout) {
        if(localStorage.getItem("lang") === 'en'){
          return "OTP Time out";
        }else{
          return "รหัส OTP หมดอายุ";
        }
        
      } else {
        if(localStorage.getItem("lang") === 'en'){
          return `Time Remaining : ${this.secondsToMinSec(
            this.countdowntime_otp
          )}`;
        }else{
          return `เวลาที่เหลือ : ${this.secondsToMinSec(
            this.countdowntime_otp
          )}`;
        }
      }
    },
    actually_username() {
      const errors = [];
      if (this.checkIsvalidUsername === false) {
        console.log("messageerrorusername ", this.messageerrorusername);
        errors.push(this.messageerrorusername);
        return errors;
      } else {
        if (!this.$v.phonenumber.$dirty) return errors;
        !this.$v.phonenumber.required &&
          errors.push(this.$t("otp_botonebox.validator_phone"));
        return errors;
      }
    },

    reasone_error_message() {
      const errors = [];
      if (this.checkIsValidReason === false) {
        errors.push(this.messageerrorreason);
        return errors;
      } else {
        if (!this.$v.reason_login.$dirty) return errors;
        !this.$v.reason_login.required &&
          errors.push(this.$t("otp_botonebox.validator_reason"));
        return errors;
      }
    },

    shower: {
      get() {
        if (this.show === true) {
          // this.dataotp = this.data2fa;
          // console.log("this.dataotp",this.dataotp );
          console.log("loginloading123",this.loginloading);
          console.log("this.data2fa", this.data2fa);
          this.reqotp = false;
          return this.show;
        }
      },
    },
  },

  data: function() {
    return {
      manyreqotp:false,
      loading:false,
      reOtptimeout:false,
      data_otp:[],
      reqotp: false,
      otp2fa: "",
      dataotp: [],
      data_id_otp: "",
      ref_otp: "",
      phonenumber: "",
      reason_login: "",
      otp_verify: "",
      messageerrorotp: "",
      messageerrorusername: "",
      messageerrorreason: "",
      otpStep: 1,
      isResendOTPImmi: false,
      isSendOtp: false,
      isOtptimeout: false,
      isCompleteOtp: false,
      checkIsvalidOTP: true,
      checkIsvalidUsername: true,
      checkIsValidReason: true,
      isloading: false,
      interval: null,
      interval2: null,
      beforeUnloadListener: null,
      showdialogOpt: false,
      countdowntime_otp: 60 * 5,
      countdowntime_reotp: 60,
      countdowntime_otp_format: "",
      requiredReason: [
        (value) => !!value || this.$t("otp_botonebox.validator_reason"),
        (value) =>
          value.length >= 5 ||
          this.$t("otp_botonebox.validator_reason_charactors"),
        (value) =>
          this.fn_checkreason(value) ||
          this.$t("otp_botonebox.validator_reason_special"),
      ],
      rules: {
        required: (value) =>
          !!value || this.$t("otp_botonebox.require_username"),
        min: (v) => v.length >= 8 || "Min 8 characters",
      },
      requiredTextphone: [
        (value) => !!value || "โปรดระบุข้อมูล.",
        (value) =>
          (value && value.length >= 10 && this.fn_checkphonenumber(value)) ||
          "โปรดระบุตัวเลข 10 หลัก",
      ],
      requiredTextphone_eng: [
        (value) => !!value || "Please fill data",
        (value) =>
          (value && value.length >= 10 && this.fn_checkphonenumber(value)) ||
          "Please fill 10 digits",
      ],
    };
  },
  methods: {
   async requiredotp2fa() {
      this.isloading = true;
      let payload = {
        mobile_no: this.data2fa.mobile_no,
      };
      let auth = await gbfGenerate.generateToken(); 
      await this.axios
        .post(
          process.env.VUE_APP_SERVICE_AUTHORIZE_API +
            "/api/request/otp",
          payload,{headers: { Authorization: auth.code },}
        )
        .then((response) => {
          console.log("Apifolder", response);
          if (response.data.status === "OK") {
            this.manyreqotp = false;
            this.reqotp = true;
            this.isloading = false;
            this.data_otp = response.data
              this.startCountdown();
              this.startCountdown_reotp();
          }else{
            if(response.data.errorMessage === 'Too many request for OTP'){
                this.manyreqotp = true;
                this.isloading = false;
                    // Toast.fire({
                    //     icon: "error",
                    //     title: response.data.errorMessage,
                    // });
            }else{
                this.manyreqotp = false;
                this.isloading = false;
                    Toast.fire({
                        icon: "error",
                        title: response.data.errorMessage,
                    });
            }
            // }
          }
        })
        .catch((error) => {
          console.log(error);
          this.isloading = false;
          Toast.fire({
            icon: "error",
            // "ไม่สามารถเชื่อมต่อไปที่ ONE-BOX ได้ในขณะนี้"
            title: this.$t("toast.cannotconnectonebox"),
          });
        });
    
    },
    fn_checkphonenumber(value) {
      const pattern = /^0[0-9]{8,9}$/i;
      if (pattern.test(value) == true) {
        return true;
      } else {
        return false;
      }
    },
    // async resendOTPimmediate() {
    //   this.isloading = true;
    //   this.isResendOTPImmi = true;
    //   if (this.data_id_otp !== "") {
    //     clearInterval(this.interval);
    //     let payload = { otp: "", status: "N", data_id: this.data_id_otp };
    //     let result = await this.fn_cancel_otp(payload);
    //     if (result.status === "OK") {
    //       this.data_id_otp = "";
    //       this.fn_resend_otp();
    //     }
    //   }
    // },
    fn_checkreason(value) {
      const pattern = /^[a-zA-Z0-9ก-๙.\s@,()&+,:;=?@#|'<>^*()%!]*$/;
      if (pattern.test(value) == true) {
        this.checkIsValidReason = true;
        return true;
      } else {
        this.checkIsValidReason = false;
        this.messageerrorreason = this.$t(
          "otp_botonebox.validator_reason_special"
        );
        return false;
      }
    },
    startCountdown() {
      clearInterval(this.interval);
      this.countdowntime_otp = 60 * 5
      this.interval = setInterval(async () => {
        if (this.countdowntime_otp > 0) {
          this.countdowntime_otp--;
          this.isOtptimeout = false;
        } else {
          clearInterval(this.interval);
          this.isOtptimeout = true;
        //   this.$emit("cancelandclosedialogotp");
        }
      }, 1000);
    },
    startCountdown_reotp() {
      clearInterval(this.interval2);
      this.countdowntime_reotp = 60
      this.interval2 = setInterval(async () => {
        if (this.countdowntime_reotp > 0) {
          this.countdowntime_reotp--;
          this.reOtptimeout = false;
        } else {
         clearInterval(this.interval2);
          this.reOtptimeout = true;
        }
      }, 1000);
    },
    async closeDialog() {
      clearInterval(this.interval);
      clearInterval(this.interval2);
      this.$emit("cancelandclosedialogotp");
    },
    countDown() {
      this.countdowntime_otp--;
    },
    secondsToMinSec(second) {
      const minutes = `${Math.floor(second / 60)}`.padStart(2, "0");
      const seconds = `${second - minutes * 60}`.padStart(2, "0");
      return `${minutes}:${seconds}`;
    },
    onCompleteOTP(otpValue) {
      this.isCompleteOtp = true;
      this.otp_verify = otpValue;
    },
    onChangedOTP() {
      this.isCompleteOtp = false;
    },
    onPasteOTP(pasteValue) {
      this.isCompleteOtp = true;
      this.otp_verify = pasteValue;
    },
    onErrorMessageOtp(msg) {
      this.checkIsvalidOTP = false;
      this.messageerrorotp = msg;
      setTimeout(() => {
        this.checkIsvalidOTP = true;
        this.messageerrorotp = "";
      }, 2000);
    },
    triggle_device(msg) {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    async checkvalidotp() {
      console.log("this.loading",this.loginloading);
    //   clearInterval(this.interval);
    //   clearInterval(this.interval2);
      this.loading = this.loading;
      this.$emit("relogin", this.otp_verify ,this.data_otp);
    },
  },
  mounted() {},
  beforeDestroy() {
    clearInterval(this.interval);
    clearInterval(this.interval2);
  },
};
</script>

<style scoped>
@media only screen and (max-width: 959px) {
  .v-stepper:not(.v-stepper--vertical) .v-stepper__label {
    display: flex !important;
  }
}
.vue-otp-input >>> .mai-classsed {
  text-align: center;
  font-weight: bold;
  width: 40px;
  height: 40px;
  border: solid 1px rgba(128, 128, 128, 0.7);
  /*  */
}
.vue-otp-input >>> .error-class {
  border: solid 2px rgba(255, 125, 125, 255);
}
/* SCREEN SIZE XS  */
@media screen and (max-width: 350px) {
  .vue-otp-input >>> .mai-classsed {
    width: 30px;
    height: 35px;
  }
}
@media (min-width: 350px) and (max-width: 370px) {
  .vue-otp-input >>> .mai-classsed {
    width: 35px;
    height: 35px;
  }
}

@media (min-width: 370px) and (max-width: 385px) {
  .vue-otp-input >>> .mai-classsed {
    width: 38px;
    height: 35px;
  }
}

@media screen and (min-width: 600px) {
  .vue-otp-input >>> .mai-classsed {
    width: 48px;
    height: 40px;
  }
}
</style>
