export default {
  formatdatetime,
  formatDateYYYYmmDD,
  formatdatetime_for_create_newdate,
};

function formatdatetime(_datetime) {
  if (_datetime === "" || _datetime === null || _datetime === undefined) {
    return "-";
  } else {
    let dateyear = _datetime.split("")[0] + _datetime.split("")[1] + _datetime.split("")[2] + _datetime.split("")[3];
    let datemonth = _datetime.split("")[4] + _datetime.split("")[5];
    let dateday = _datetime.split("")[6] + _datetime.split("")[7];
    let hour = _datetime.split("")[8] + _datetime.split("")[9];
    let minute = _datetime.split("")[10] + _datetime.split("")[11];
    let second = _datetime.split("")[12] + _datetime.split("")[13];

    return dateday + "/" + datemonth + "/" + dateyear + " " + hour + ":" + minute + ":" + second;
  }
}

function formatdatetime_for_create_newdate(_datetime) {
  if (_datetime === "" || _datetime === null || _datetime === undefined) {
    return "-";
  } else {
    let dateyear = _datetime.split("")[0] + _datetime.split("")[1] + _datetime.split("")[2] + _datetime.split("")[3];
    let datemonth = _datetime.split("")[4] + _datetime.split("")[5];
    let dateday = _datetime.split("")[6] + _datetime.split("")[7];
    let hour = _datetime.split("")[8] + _datetime.split("")[9];
    let minute = _datetime.split("")[10] + _datetime.split("")[11];
    let second = _datetime.split("")[12] + _datetime.split("")[13];

    return dateyear + "/" + datemonth + "/" + dateday + " " + hour + ":" + minute + ":" + second;
  }
}

function formatDateYYYYmmDD(_carlendar, _destination) {
  // _carlendar ค่าวันที่ที่รับมา format เป็นแบบ YYYY-MM-DD
  // _destination ไว้บอกว่าที่รับค่ามาเป็นของ formdate หรือ todate
  if (_carlendar === "" || _carlendar === null || _carlendar === undefined) {
    if (_destination === "from") {
      return "00000000000000";
    } else if (_destination === "to") {
      return "99999999999999";
    } else {
      return "99999999999999";
    }
  } else {
    let arrayYYYYmmDD = _carlendar.split("-");
    if (_destination === "from") {
      return arrayYYYYmmDD[0] + arrayYYYYmmDD[1] + arrayYYYYmmDD[2] + "000000";
    } else if (_destination === "to") {
      return arrayYYYYmmDD[0] + arrayYYYYmmDD[1] + arrayYYYYmmDD[2] + "999999";
    } else {
      return "99999999999999";
    }
  }
}
